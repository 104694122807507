import React from 'react';
import RenderContent from '../components/RenderContent'
import { Image } from '../components/Image'

import './ProductColumn.scss';

export const ProductColumn = props => {
  const { productColumn, sectionId } = props;
  return (
    <section className="flexible" name="product-columns" id={ sectionId ? sectionId : null}>
      <div className="column-wrapper">
        {productColumn.map((col, index) => (
          <div className="column-container" key={index}>
            <div className="image-wrapper">
              <a alt="Click here to purchase" target="_blank" rel="noopener" href={`${col.productId ? col.productId : '#'}`}>
                <Image src={col.image.source_url} />
              </a>
            </div>
            <div className="rendered-content">
              <RenderContent content={col.content} />
              <div className="product-well-wrapper">
                <hr />
                <RenderContent content={col.pricing} className={`product-well`}/>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
