// Component to render out the page consent
import React from 'react'
import Cookies from 'universal-cookie';
import {RemoveScroll} from 'react-remove-scroll';

import './AgeConsent.scss'

const isClient = typeof window !== 'undefined';

const enableBodyScroll = () => {
  if (isClient) {
    document.body.classList.remove('nav-open-lite');
  }
}

let cookieName = 'ROF_COOKIE';

const cookies = new Cookies();

export default class AgeConsent extends React.PureComponent {
  constructor(props) {
    super(props);
    // cookies.get() will return the value of the cookie which is a STRING
    this.state = {
      isPrevVerified: cookies.get(cookieName) === 'true' ? true : false,
    }
  }  
  handleClickYes = () => {
    enableBodyScroll();
    let aYearFromNow = new Date();
    aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);
    cookies.set(cookieName, true, { path: '/', expires: aYearFromNow})
    this.setState({
      isPrevVerified: true,
    })
  }

  componentDidMount = () => {
    if (this.state.isPrevVerified) {
      return null;
    } else if (!this.state.isPrevVerified) {
      document.body.classList.add('nav-open-lite');
    }
  }

  handleClickNo = () => {
    alert('You must be 18 years and older to enter this website.')
  }

  render() {
    const { isPrevVerified } = this.state;
    return (
      <>{!isPrevVerified &&
        <RemoveScroll>
        <div className="age-verification-overlay">
          <div className="logo">
            <img width='250' height='250' src='/logos/RoFPrimaryLogoRGB.svg' alt="Republic of Fremantle logo with rose gold colouring" />
          </div>
          <div className="content-wrapper">
            <h1>Welcome to the republic of Fremantle</h1>
            <div className='verification-wrapper'>
              <p>You must be 18 years of age or older to enter</p>
              <div className="button-wrapper">
                <button className="action" onClick={this.handleClickYes}>
                  YES              
                </button>
                <button className="action" onClick={this.handleClickNo}>
                  NO
                </button>
              </div>
            </div>
          </div>
          <div className="legal-disclaimer">
            <p>
              <strong>WARNING</strong><br />
                Under the Liquor Control Act 1988, it is an offence:<br />
                • to sell or supply liquor to a person under the age of 18 years on licensed or regulated premises; or <br />
                • for a person under the age of 18 years to purchase, or attempt to purchase, liquor on licensed or regulated premises. <br />
            </p>
          </div>
        </div>
        </RemoveScroll> 
        }
      </>
    )
  }
}