import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { Image } from '../../Image'
import Carousel from '../../Carousel';
import './Gallery.scss';

export const GalleryPass = props => {

  const { urls, data } = props;
  const mediaEdge = data.allWordpressWpMedia.edges

  const settings = {
    container:'gallery-carousel',
    nav: true,
    mouseDrag: true,
    items: 1,
    controls: false,
    controlsPosition:'bottom',
    navPosition:'bottom',
  };


  if (urls) {
    const urlArray = urls.replace(/['"]+/g, '').split(',');
    return (
      <div className="gallery-slider">
        <Carousel settings={settings}>
        {urlArray.map((iurl, index) => {
          let image = mediaEdge.filter(m => {
            return m.node.source_url === iurl
          })
          if (image[0]) {
            image = image[0].node
            return (
              <div key={index} className="slide" style={{ position: "relative" }}>
                <div className="inside">
                  <Image src={image} position="" />
                </div>
              </div>
            )
          } else {
            //Return just the image as before
            return <div key={index} className="slide" style={{ position: "relative" }}>No local store</div>;
          }
        })}
        </Carousel>
      </div>
    )
  }else{
    return null;
  }


}



export const Gallery = (props) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          allWordpressWpMedia {
            edges {
              node {
                id
                source_url
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200, quality: 90) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <GalleryPass {...props} data={data} />}
    />
  );
}
