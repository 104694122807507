import React from 'react';
import { Link } from 'gatsby';
import RenderContent from '../components/RenderContent'
import { Image } from '../components/Image'
import './GrowthChart.scss';

export const GrowthChart = props => {
  const { content, growthNumber } = props;
  return (
    <div>
      <RenderContent content={content} />
      {growthNumber}
    </div>
  );
};
