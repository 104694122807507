import React from 'react';
import { RelatedPosts } from '../components/RelatedPosts';
import { RelatedProjects } from '../components/RelatedProjects'
export const BlogFeed = (props) => {
  if (props.contentType === 'project') {
    return <RelatedProjects {...props} />
  }else{
    return <RelatedPosts {...props} />
  }
}
