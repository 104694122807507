import React from 'react';
import OnVisible from 'react-on-visible';
import { withPrefix } from 'gatsby';
import { RenderShortcode } from '../components/RenderShortcode/RenderShortcode';
import { SideNavigation } from '../components/SideNavigation'
import { Image } from '../components/Image'
import cx from 'classnames';
import './FlexibleBlock.scss';
import '../../theme/FlexibleBlock.scss';
import { decodeEntities } from '../utils/htmlParse';

export const FlexibleBlock = props => {

  const getComponent = (item, key, pageContext) => {
    const {
      backgroundImagePosition,
      backgroundImageSize,
      backgroundStyle,
      content_type,
      contentVideo,
      iframe,
      section_content,
      content_background_image,
      imageAccent,
      icons,
      iconsIntro,
      iconsCenter
      } = item;
      const isSecondImage = key === 1;
      const isMobileImage = key === 'mobile';
   
      return (
        <div
          className={`col${content_type ? ` ${content_type}` : ''}${isMobileImage && content_type === 'image' ? ' mobile' : ''}${isSecondImage && content_type === 'image' ? ' desktop' : ''}${backgroundStyle && backgroundStyle != '' && backgroundStyle != 'none'  ? ' ' + backgroundStyle : ''}`}
          key={key}
          >
          {content_type == 'video' && <div className="inner-content video-block" dangerouslySetInnerHTML={{__html: contentVideo}} />}
          {content_type == 'iframe' && <div className="inner-content iframe-block">
            <iframe src={iframe} frameBorder="0" allowFullScreen /></div>
          }

          {content_type == 'image' && content_background_image && 
            <div 
              className={`inner-content image-block 
                ${backgroundImageSize ? `img-bgSize-${backgroundImageSize}` : ''}
                ${backgroundImagePosition ? `img-bgPos-${backgroundImagePosition}` : ''} 
              `}>
            <Image src={content_background_image} />
            </div>
           } 
          {imageAccent && 
            <div className="flexible-image-accent-wrapper">
              <Image src={imageAccent} />
            </div>
          }

          {content_type == 'text' && <div className="inner-content text-block"><RenderShortcode content={section_content} /></div>}
          {content_type == 'menu' && <div className="inner-content text-block menu-block"><SideNavigation pageContext={pageContext}/><RenderShortcode content={section_content} /></div>}
          {content_type == 'icons' &&
              <div className={`inner-content icons-block${iconsCenter ? ' center' : ''}`}>
                {iconsIntro && <RenderShortcode content={iconsIntro} />}
                <ul>
                {icons.map((item, index) => (
                  <li key={index}>
                    <Image className="inner-image" src={item.icon.source_url} />
                    {item.iconTitle && <p>{decodeEntities(item.iconTitle)}</p>}
                  </li>
                ))}
                </ul>
              </div>
          }
        </div>
      );
  };

  const {
    columns = [],
    section,
    wrapWidth = wrapWidth ? wrapWidth : 'wrap',
    backgroundStyle,
    backgroundImage,
    backgroundImagePosition,
    backgroundImageSize,
    sectionTitle,
    sectionId,
    sectionClassName,
    pageContext
  } = props;
  const sectionClassPad = section ? ' section' : '';
  const column_number = ['zero','one','two','three','four'];
  const sectionClass = sectionClassName && sectionClassName != 'none' ? cx('flexible', sectionClassName) : `flexible block`;
  const globalClasses = backgroundStyle ? cx(sectionClass, backgroundStyle) : sectionClass;
  const containsMenu = columns[0] && columns[0].content_type === 'menu' || columns[1] && columns[1].content_type === 'menu' || columns[2] && columns[2].content_type === 'menu' ? true : false
  return (
    <section className={globalClasses} id={sectionId ? sectionId : null}>
      <div className={`${column_number[columns.length]}-col${wrapWidth === 'none' || wrapWidth == '' ? '' : ` ${wrapWidth}`}${sectionClassPad}${containsMenu ? ' contains-menu' : ''}`}>
        <div className={`inner`}>
          {//Mobile image column
            columns && columns[1] && columns[1].content_type === 'image' ? getComponent(columns[1],'mobile') : null }
          {columns.map((item, index) => getComponent(item, index, pageContext)) }
        </div>
      </div>
      {backgroundImage && <div className="section-background"><Image src={backgroundImage}/></div>}
    </section>
  );
}
