import React from 'react';
import GatsbyLink from '../components/GatsbyLink';
import RenderContent from '../components/RenderContent'
import { Image } from '../components/Image'
import './LinkList.scss';

export const LinkList = props => {
  const { links, title, introContent } = props;
  return (
    <section className="links-list">
      <div className="wrap">
        {title && <h3 className="section-title">{title}</h3>}
        {introContent && <RenderContent className="intro-text" content={introContent}/>}
        <div className="list">
          {links && links.map((link, index) => (
            <GatsbyLink className="item" key={index} to={link.link.url} target={link.link.target}>
              <div className="inner">
                <div className="content">
                  <div className="wrapper">
                    {link.description && <RenderContent content={link.description}/>}
                    {link.link.title && <button className="action">{link.link.title}</button>}
                  </div>
                </div>
                {link.image && link.image && <div className="background"><Image src={link.image} /></div>}
              </div>
            </GatsbyLink>
          ))}
        </div>
      </div>
    </section>
  );
};
