import React from 'react';
import RenderContent from '../components/RenderContent'
import { Image } from '../components/Image'
/* TO DO */
// import './BlockQuote.scss';

export const BlockQuote = props => {
  // const {} = options;
  const { quote, quoteAuthor, image } = props;
  return (
    <div style={{textAlign: `center`, margin: `0 auto`}}>
      <RenderContent content={quote} />
      <div className="image-wrapper" style={{textAlign: `center`, width: `300px`, position: `relative`}}>
        <Image src={image.source_url} />
      </div>
      <p>
        {quoteAuthor}
      </p>
    </div>
  );
};
